import React from 'react';
import { graphql } from 'gatsby';
import { Section, SectionTitle } from 'react-komponents';

import { PageLayout } from '../components/Layouts/layout';

const BlogPost = ({ location, data: { post } }) => {
  return (
    <PageLayout location={location}>
      <Section beta>
        <SectionTitle>{post.frontmatter.title}</SectionTitle>
        <div className="post" dangerouslySetInnerHTML={{ __html: post.html }} />
      </Section>
    </PageLayout>
  );
};

export const pageQuery = graphql`
  query projectQuery($slug: String!) {
    post: markdownRemark(fields: { slug: { eq: $slug } }) {
      fields {
        slug
      }
      excerpt
      timeToRead
      html
      frontmatter {
        title
        categories
        lang
        thumbnail {
          childImageSharp {
            gatsbyImageData(formats: WEBP, width: 500, layout: CONSTRAINED)
          }
        }
        date
        template
      }
    }
  }
`;

export default BlogPost;
